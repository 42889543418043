import React from "react";

const InfoCard = ({ icon, cardTitle, cardDesc, className }) => {
  return (
    <>
      <div
        className={`bg-white h-[100px] w-full rounded-2xl shadow-custom flex items-center ${className}`}
      >
        <div className="flex items-center justify-center w-[50px] h-[50px] bg-primary-light rounded-full mx-4">
          {icon}
        </div>
        <div className="flex flex-col items-start">
          <div className="text-xl font-medium text-secondary-light">
            {cardTitle}
          </div>
          <div className="text-xl font-medium text-dark">{cardDesc}</div>
        </div>
      </div>
    </>
  );
};

export default InfoCard;
