import React, { useState, useEffect } from "react";

const LogoutNotification = ({ onEditTask, onLogoutNow }) => {
  const [close, setClose] = useState(true);
  const [timeLeft, setTimeLeft] = useState(30);

  const handleEditTaskClick = () => {
    if (onEditTask) {
      onEditTask(); // Call the function to cancel logout
    }
  };

  const handleLogoutNowClick = () => {
    if (onLogoutNow) {
      onLogoutNow(); // Call the function to immediately logout
      setClose(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          setClose(false);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      {close && (
        <>
          <div className="flex items-center justify-between bg-white p-5 shadow-3xl">
            <p>You will be logged out of application in {timeLeft} seconds</p>

            <div className="flex items-center justify-between gap-5">
              <div
                className="bg-secondary-dark hover:bg-primary-dark w-fit rounded-full text-white flex items-center justify-center px-8 py-2 cursor-pointer"
                onClick={handleEditTaskClick}
              >
                Edit My Task
              </div>
              <div
                className="bg-secondary-dark hover:bg-[red] w-fit rounded-full text-white flex items-center justify-center px-8 py-2 cursor-pointer"
                onClick={handleLogoutNowClick}
              >
                Logout Now
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LogoutNotification;
