import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children, allowedRoles }) => {
  const userRole = localStorage.getItem("userRole");

  if (!userRole) {
    // If no userRole is found, redirect to login
    return <Navigate to="/login" />;
  }

  if (!allowedRoles.includes(userRole.toLowerCase())) {
    // If userRole is not in the allowedRoles, show not authorized or redirect
    return <Navigate to="*" />;
  }

  // If role is allowed, render the children components
  return children;
};

export default PrivateRoute;
