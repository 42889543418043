import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Sidebar from "./Components/Sidebar.jsx";
import Login from "./Pages/Login.jsx";
import NotFound from "./Pages/NotFound.jsx";
import Dashboard from "./Pages/Dashboard.jsx";
import Operator from "./Pages/Operator.jsx";
import Machine from "./Pages/Machine.jsx";
import User from "./Pages/User.jsx";
import Task from "./Pages/Task.jsx";
import MachineDetail from "./Pages/MachineDetail.jsx";
import PrivateRoute from "./PrivateRoute.jsx";
const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/operator"
            element={
              <PrivateRoute allowedRoles={["operator", "admin", "superadmin"]}>
                <Operator />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute allowedRoles={["admin", "superadmin"]}>
                <Sidebar>
                  <Dashboard />
                </Sidebar>
              </PrivateRoute>
            }
          />
          <Route
            path="/machine"
            element={
              <PrivateRoute allowedRoles={["admin", "superadmin"]}>
                <Sidebar>
                  <Machine />
                </Sidebar>
              </PrivateRoute>
            }
          />
          <Route
            path="/machine-detail/:taskId"
            element={
              <PrivateRoute allowedRoles={["admin", "superadmin", "operator"]}>
                <MachineDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/user"
            element={
              <PrivateRoute allowedRoles={["admin", "superadmin"]}>
                <Sidebar>
                  <User />
                </Sidebar>
              </PrivateRoute>
            }
          />
          <Route
            path="/task"
            element={
              <PrivateRoute allowedRoles={["admin", "superadmin"]}>
                <Sidebar>
                  <Task />
                </Sidebar>
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
