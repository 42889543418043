import React from "react";
import MachineBG from "../Assets/Images/machine_bg.png";
import { Link } from "react-router-dom";

const MachineCard = ({ task }) => {
  if (!task) {
    return <div>Loading...</div>; // or some other placeholder
  }

  return (
    <div className="bg-white shadow-custom p-5 rounded-2xl w-fit">
      <div>
        <img src={MachineBG} alt="Machine Background" className="rounded-2xl" />
      </div>
      <div className="py-3">
        <h4 className="font-medium text-black text-xl pb-1">
          {task.TaskName}
        </h4>
        <p className="text-secondary-light font-medium text-md">
          Project: {task.ProjectId || "No Project ID"}
        </p>
      </div>
      <div className="flex items-center gap-5 justify-between">
        <h4 className="font-bold text-black text-md">
          Current Status :{" "}
          <span className="text-[red] uppercase">
            {task.TaskStatus}
          </span>
        </h4>
        <Link
          to={`/machine-detail/${task.TaskId}`} // Dynamically link to detail page based on task ID
          className="bg-secondary-dark hover:bg-primary-dark text-white w-fit px-5 py-2 rounded-full cursor-pointer"
        >
          View
        </Link>
      </div>
    </div>
  );
};

export default MachineCard;
