import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import { FaArrowLeftLong } from "react-icons/fa6";
import ExcelUpload from "../Components/ExcelUpload";
import { TiEye } from "react-icons/ti";
import { FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Table from "../Components/Table";

const Task = () => {
  const [addProject, setAddProject] = useState(false);
  const [addTask, setAddTask] = useState(false);
  const [addProcess, setAddProcess] = useState(false);
  const [selectedOption, setSelectedOption] = useState("allProject");
  const [projectsData, setProjectsData] = useState([]);
  const [tasksData, setTasksData] = useState([]);
  const [processesData, setProcessesData] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [buttonState, setButtonState] = useState("create");

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [formData, setFormData] = useState({
    projectName: "",
    projectId: "",
    projectStatus: "",
    startDate: "",
    deliveryDate: "",
    projectDescription: "",
    taskId: "",
    taskName: "",
    numberOfProcesses: "",
    numberOfPiecesToBeCreated: "",
    taskDeliveryDate: "",
    expectedCompletionTime: "",
    taskStatus: "",
    taskDescription: "",
    operatorsWorking: "",
    processId: "",
    processName: "",
    onMachine: "",
    assignTo: "",
    numberOfOPeratorRequired: "",
    operatorWorking: "",
    processStatus: "",
    numberOfPieceDone: "",
    totalTimeTaken: "",
    remarks: "",
    previousOperator: "",
    processDescription: "",
  });

  const fetchData = async () => {
    try {
      // Fetch projects data
      const projectsResponse = await fetch(
        "https://cnc.swastik.digital/api/api.php?method=project&action=getAll"
      );
      const projectsResult = await projectsResponse.json();
      if (projectsResult.status) {
        setProjectsData(projectsResult.msg);
      } else {
        console.log(projectsResult.msg);
      }

      // Fetch tasks data
      const tasksResponse = await fetch(
        "https://cnc.swastik.digital/api/api.php?method=task&action=getAll"
      );
      const tasksResult = await tasksResponse.json();
      if (tasksResult.status) {
        setTasksData(tasksResult.msg);
      } else {
        console.log(tasksResult.msg);
      }

      // Fetch processes data
      const processesResponse = await fetch(
        "https://cnc.swastik.digital/api/api.php?method=process&action=getAll"
      );
      const processesResult = await processesResponse.json();
      if (processesResult.status) {
        setProcessesData(processesResult.msg);
      } else {
        console.log(processesResult.msg);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("An error occurred while fetching the data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmitProject = async (e, action) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `https://cnc.swastik.digital/api/api.php?method=project&action=${action}&projectId=${encodeURIComponent(
          formData.projectId
        )}&name=${encodeURIComponent(
          formData.projectName
        )}&status=${encodeURIComponent(
          formData.projectStatus
        )}&startDate=${encodeURIComponent(
          formData.startDate
        )}&deliveryDate=${encodeURIComponent(
          formData.deliveryDate
        )}&description=${encodeURIComponent(formData.projectDescription)}`,
        {
          method: "GET", // GET method since parameters are in the URL
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.status) {
        alert(result.msg);
        fetchData();
        setFormData({
          projectName: "",
          projectStatus: "",
          startDate: "",
          projectDescription: "",
          deliveryDate: "",
          projectId: "", // Clear the machineId
        });
        setButtonState("create");
      } else {
        alert(result.msg);
      }
    } catch (error) {
      console.error("Error adding Project:", error);
      alert("An error occurred while adding the project");
    }
  };

  const handleSubmitTask = async (e, action) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `https://cnc.swastik.digital/api/api.php?method=task&action=${action}&taskId=${encodeURIComponent(
          formData.taskId
        )}&taskName=${encodeURIComponent(
          formData.taskName
        )}&projectId=${encodeURIComponent(
          formData.projectId
        )}&numProcesses=${encodeURIComponent(
          formData.numProcesses
        )}&numPieces=${encodeURIComponent(
          formData.numPieces
        )}&deliveryDate=${encodeURIComponent(
          formData.deliveryDate
        )}&expectedCompletionTime=${encodeURIComponent(
          formData.expectedCompletionTime
        )}&taskStatus=${encodeURIComponent(
          formData.taskStatus
        )}&taskDescription=${encodeURIComponent(formData.taskDescription)}`,
        {
          method: "GET", // GET method since parameters are in the URL
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.status) {
        alert(result.msg);
        fetchData(); // Assuming fetchData is a function to refresh data after submission
        handleCancelForm();
      } else {
        alert(result.msg);
      }
    } catch (error) {
      console.error("Error adding Task:", error);
      alert("An error occurred while adding the task");
    }
  };

  const handleSubmitProcess = async (e, action) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `https://cnc.swastik.digital/api/api.php?method=process&action=${action}&processName=${encodeURIComponent(
          formData.processName
        )}&taskId=${encodeURIComponent(
          formData.taskId
        )}&projectId=${encodeURIComponent(
          formData.projectId
        )}&processId=${encodeURIComponent(
          formData.processId
        )}&onMachine=${encodeURIComponent(
          formData.onMachine
        )}&assignTo=${encodeURIComponent(
          formData.assignTo
        )}&numOperators=${encodeURIComponent(
          formData.numberOfOPeratorRequired
        )}&operatorsWorking=${encodeURIComponent(
          formData.operatorWorking
        )}&processStatus=${encodeURIComponent(
          formData.processStatus
        )}&numPiecesToBeCreated=${encodeURIComponent(
          formData.numberOfPiecesToBeCreated
        )}&numPiecesDone=${encodeURIComponent(
          formData.numberOfPieceDone
        )}&totalTimeTaken=${encodeURIComponent(
          formData.totalTimeTaken
        )}&expectedCompletionTime=${encodeURIComponent(
          formData.expectedCompletionTime
        )}&remarks=${encodeURIComponent(
          formData.remarks
        )}&processDescription=${encodeURIComponent(
          formData.processDescription
        )}`,
        {
          method: "GET", // GET method since parameters are in the URL
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.status) {
        alert(result.msg);
        fetchData();
        handleCancelForm();
      } else {
        alert(result.msg);
      }
    } catch (error) {
      console.error("Error adding Process:", error);
      alert("An error occurred while adding the process");
    }
  };

  const handleViewProject = (project) => {
    setSelectedProject(project);
    setButtonState("edit");
    setAddProject(true);
    setAddTask(false);
    setAddProcess(false);
    setFormData({
      projectId: project.ProjectId,
      projectName: project.Name,
      projectStatus: project.Status,
      startDate: project.StartDate,
      deliveryDate: project.DeliveryDate,
      projectDescription: project.Description,
    });
  };

  const handleViewTask = (task) => {
    setSelectedProject(task);
    setButtonState("edit");
    setAddProject(false);
    setAddProcess(false);
    setAddTask(true);
    setFormData({
      projectId: task.ProjectId,
      taskId: task.TaskId,
      taskName: task.TaskName,
      taskStatus: task.TaskStatus,
      numProcesses: task.NumberOfProcesses,
      numPieces: task.NumberOfPiecesToBeCreated,
      deliveryDate: task.DeliveryDate,
      expectedCompletionTime: task.ExpectedCompletionTime,
      taskDescription: task.TaskDescription,
    });
  };

  const handleViewProcess = (process) => {
    setSelectedProject(process);
    setButtonState("edit");
    setAddProcess(true);
    setAddProject(false);
    setAddTask(false);
    setFormData({
      processId: process.ProcessId,
      taskId: process.TaskId,
      projectId: process.ProjectId,
      processName: process.ProcessName,
      onMachine: process.MachineOn,
      assignTo: process.AssignTo,
      numberOfOPeratorRequired: process.NumberOfOPeratorRequired,
      operatorWorking: process.OperatorWorking,
      processStatus: process.ProcessStatus,
      numberOfPiecesToBeCreated: process.NumberOfPiecesToBeCreated,
      numberOfPieceDone: process.NumberOfPieceDone,
      totalTimeTaken: process.TotalTimeTaken,
      expectedCompletionTime: process.ExpectedCompletionTime,
      remarks: process.Remarks,
      processDescription: process.ProcessDescription,
      previousOperator: process.PreviousOperator,
    });
  };

  const handleDeleteProject = async (projectId) => {
    try {
      const response = await fetch(
        `https://cnc.swastik.digital/api/api.php?method=project&action=delete&projectId=${projectId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.status) {
        alert(result.msg);
        // Directly update the state if possible
        setProjectsData((prevProjectsData) =>
          prevProjectsData.filter((project) => project.ProjectId !== projectId)
        );
        handleCancelForm();
      } else {
        alert(result.msg);
      }
    } catch (error) {
      console.error("Error deleting project:", error);
      alert("An error occurred while deleting the project");
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      const response = await fetch(
        `https://cnc.swastik.digital/api/api.php?method=task&action=delete&taskId=${taskId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.status) {
        alert(result.msg);
        // Directly update the state if possible
        setTasksData((prevTasksData) =>
          prevTasksData.filter((task) => task.TaskId !== taskId)
        );
        handleCancelForm();
      } else {
        alert(result.msg);
      }
    } catch (error) {
      console.error("Error deleting task:", error);
      alert("An error occurred while deleting the task");
    }
  };

  const handleDeleteProcess = async (processId) => {
    try {
      const response = await fetch(
        `https://cnc.swastik.digital/api/api.php?method=process&action=delete&processId=${processId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.status) {
        alert(result.msg);
        // Directly update the state if possible
        setProcessesData((prevProcessesData) =>
          prevProcessesData.filter((process) => process.ProcessId !== processId)
        );
        handleCancelForm();
      } else {
        alert(result.msg);
      }
    } catch (error) {
      console.error("Error deleting process:", error);
      alert("An error occurred while deleting the process");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCancelForm = () => {
    setFormData({
      projectName: "",
      projectId: "",
      projectStatus: "",
      startDate: "",
      deliveryDate: "",
      projectDescription: "",
      taskId: "",
      taskBelongsToProject: "", // ProjectId or TaskId
      taskName: "",
      numProcesses: "",
      numPieces: "",
      taskDeliveryDate: "",
      expectedCompletionTime: "",
      taskStatus: "",
      taskDescription: "",
      operatorsWorking: "",
      processId: "",
      processName: "",
      processBelongsToTask: "",
      onMachine: "",
      assignTo: "",
      numberOfOperatorRequired: "",
      operatorWorking: "",
      processStatus: "",
      numberOfPieceDone: "",
      totalTimeTaken: "",
      remarks: "",
      previousOperator: "",
    });
    setButtonState("create");
  };

  return (
    <>
      <Navbar pageName="Task" />

      <div className="flex flex-col lg:flex-row pt-4">
        {/* buttons */}
        {!(addProject || addTask || addProcess) && (
          <>
            <div className="flex flex-col w-full lg:mr-4 mb-4 lg:mb-0 gap-4">
              <div className="flex w-full lg:mr-4 mb-4 lg:mb-0 gap-4">
                <div className="flex w-full h-full py-10 px-0 bg-white hover:bg-secondary-light items-center justify-center rounded-2xl shadow cursor-pointer">
                  <ExcelUpload />
                </div>

                {/* add Project Button */}
                <div
                  className="flex w-full h-[200px] lg:h-50 bg-white hover:bg-secondary-light items-center justify-center rounded-2xl shadow cursor-pointer"
                  onClick={() => {
                    setAddProject(true);
                  }}
                >
                  Add Project
                </div>
              </div>
              <div className="flex w-full lg:mr-4 mb-4 lg:mb-0 gap-4">
                {/* add Task Button */}
                <div
                  className="flex w-full h-[200px] lg:h-50 bg-white hover:bg-secondary-light items-center justify-center rounded-2xl shadow cursor-pointer"
                  onClick={() => {
                    setAddTask(true);
                  }}
                >
                  Add Task
                </div>

                {/* add excel Button */}
                <div
                  className="flex w-full h-[200px] lg:h-50 bg-white hover:bg-secondary-light items-center justify-center rounded-2xl shadow cursor-pointer"
                  onClick={() => {
                    setAddProcess(true);
                  }}
                >
                  Add Process
                </div>
              </div>
            </div>
          </>
        )}

        {/* Project Creation Form */}
        {addProject && (
          <div className="w-full lg:mr-4 mb-4 lg:mb-0">
            {/* header */}
            <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pt-4 pb-[20px] shadow-2xl shadow-gray-100 border-b">
              <div
                className="hover:bg-primary-light bg-white border cursor-pointer border py-2 px-4 rounded-full text-primary-dark"
                onClick={() => {
                  setAddProject(false);
                  handleCancelForm();
                }}
              >
                <FaArrowLeftLong />
              </div>

              <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                {buttonState === "create" && "Add Project"}
                {buttonState === "edit" && "View Project"}
                {buttonState === "save" && "Edit Project"}
              </h4>
              {buttonState === "create" && (
                <button
                  onClick={(e) => handleSubmitProject(e, "insert")}
                  className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                >
                  Create
                </button>
              )}
              {buttonState === "edit" && (
                <div>
                  <button
                    onClick={() => {
                      setButtonState("save");
                    }}
                    className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 mr-5 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                  >
                    Edit
                  </button>
                  <button
                    onClick={handleCancelForm}
                    className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                  >
                    Cancel
                  </button>
                </div>
              )}
              {buttonState === "save" && (
                <div>
                  <button
                    onClick={(e) => handleSubmitProject(e, "update")}
                    className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 mr-5 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                  >
                    Save
                  </button>
                  <button
                    onClick={handleCancelForm}
                    className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>

            <div className="bg-white w-full overflow-x-scroll px-4 py-6 md:overflow-x-hidden rounded-b-2xl shadow-custom">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="projectId"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Project Id
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="projectId"
                      id="projectId"
                      value={formData.projectId}
                      onChange={handleChange}
                      disabled
                      autoComplete="off"
                      className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="projectName"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Project Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="projectName"
                      id="projectName"
                      autoComplete="off"
                      disabled={buttonState === "edit"}
                      value={formData.projectName}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          projectName: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="projectStatus"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Project Status
                  </label>
                  <div className="mt-2">
                    <select
                      id="projectStatus"
                      name="projectStatus"
                      autoComplete="projectStatus"
                      disabled={buttonState === "edit"}
                      value={formData.projectStatus}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          projectStatus: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option>Pending</option>
                      <option>Cancelled</option>
                      <option>In Progress</option>
                      <option>Completed</option>
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="startDate"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Start Date
                  </label>
                  <div className="mt-2">
                    <input
                      type="date"
                      name="startDate"
                      id="startDate"
                      value={formData.startDate}
                      disabled={buttonState === "edit"}
                      onChange={(e) =>
                        setFormData({ ...formData, startDate: e.target.value })
                      }
                      className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="deliveryDate"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Delivery Date
                  </label>
                  <div className="mt-2">
                    <input
                      type="date"
                      name="deliveryDate"
                      id="deliveryDate"
                      disabled={buttonState === "edit"}
                      value={formData.deliveryDate}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          deliveryDate: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="projectDescription"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Project Description
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="projectDescription"
                      name="projectDescription"
                      rows={3}
                      value={formData.projectDescription}
                      disabled={buttonState === "edit"}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          projectDescription: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Task Creation form */}
        {addTask && (
          <>
            <div className="w-full lg:mr-4 mb-4 lg:mb-0">
              {/* Header */}
              <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pt-4 pb-[20px] shadow-2xl shadow-gray-100 border-b">
                <div
                  className="hover:bg-primary-light bg-white border cursor-pointer border py-2 px-4 rounded-full text-primary-dark"
                  onClick={() => {
                    setAddTask(false);
                    handleCancelForm();
                  }}
                >
                  <FaArrowLeftLong />
                </div>

                <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                  {buttonState === "create" && "Add Task"}
                  {buttonState === "edit" && "View Task"}
                  {buttonState === "save" && "Edit Task"}
                </h4>
                {buttonState === "create" && (
                  <button
                    onClick={(e) => handleSubmitTask(e, "insert")}
                    className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                  >
                    Create
                  </button>
                )}
                {buttonState === "edit" && (
                  <div>
                    <button
                      onClick={() => {
                        setButtonState("save");
                      }}
                      className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 mr-5 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                    >
                      Edit
                    </button>
                    <button
                      onClick={handleCancelForm}
                      className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                    >
                      Cancel
                    </button>
                  </div>
                )}
                {buttonState === "save" && (
                  <div>
                    <button
                      onClick={(e) => handleSubmitTask(e, "update")}
                      className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 mr-5 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                    >
                      Save
                    </button>
                    <button
                      onClick={handleCancelForm}
                      className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>

              {/* Form */}
              <div className="bg-white w-full overflow-x-scroll px-4 py-6 md:overflow-x-hidden rounded-b-2xl shadow-custom">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="taskId"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Task Id
                    </label>

                    <input
                      type="text"
                      name="taskId"
                      id="taskId"
                      autoComplete="off"
                      disabled
                      value={formData.taskId}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          projectId: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="projectId"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Belongs To Project
                    </label>

                    <input
                      type="text"
                      name="projectId"
                      id="projectId"
                      autoComplete="off"
                      disabled={buttonState === "edit"}
                      value={formData.projectId}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          projectId: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="taskName"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Task Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="taskName"
                        id="taskName"
                        autoComplete="off"
                        disabled={buttonState === "edit"}
                        value={formData.taskName}
                        onChange={(e) =>
                          setFormData({ ...formData, taskName: e.target.value })
                        }
                        className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="numProcesses"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Number of Processes
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        name="numProcesses"
                        id="numProcesses"
                        autoComplete="off"
                        disabled={buttonState === "edit"}
                        value={formData.numProcesses}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            numProcesses: e.target.value,
                          })
                        }
                        className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="numPieces"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Number of Pieces
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        name="numPieces"
                        id="numPieces"
                        disabled={buttonState === "edit"}
                        autoComplete="off"
                        value={formData.numPieces}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            numPieces: e.target.value,
                          })
                        }
                        className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="deliveryDate"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Delivery Date
                    </label>
                    <div className="mt-2">
                      <input
                        type="date"
                        name="deliveryDate"
                        id="deliveryDate"
                        disabled={buttonState === "edit"}
                        value={formData.deliveryDate}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            deliveryDate: e.target.value,
                          })
                        }
                        className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="expectedCompletionTime"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Expected Completion Time
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="expectedCompletionTime"
                        id="expectedCompletionTime"
                        autoComplete="off"
                        disabled={buttonState === "edit"}
                        value={formData.expectedCompletionTime}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            expectedCompletionTime: e.target.value,
                          })
                        }
                        className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="taskStatus"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Task Status
                    </label>
                    <div className="mt-2">
                      <select
                        id="taskStatus"
                        name="taskStatus"
                        autoComplete="taskStatus"
                        disabled={buttonState === "edit"}
                        value={formData.taskStatus}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            taskStatus: e.target.value,
                          })
                        }
                        className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option>Pending</option>
                        <option>In Progress</option>
                        <option>Completed</option>
                        <option>Cancelled</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="taskDescription"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Task Description
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="taskDescription"
                        name="taskDescription"
                        rows={3}
                        value={formData.taskDescription}
                        disabled={buttonState === "edit"}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            taskDescription: e.target.value,
                          })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Process Creation form */}
        {addProcess && (
          <>
            <div className="w-full lg:mr-4 mb-4 lg:mb-0">
              {/* Header */}
              <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pt-4 pb-[20px] shadow-2xl shadow-gray-100 border-b">
                <div
                  className="hover:bg-primary-light bg-white border cursor-pointer border py-2 px-4 rounded-full text-primary-dark"
                  onClick={() => {
                    setAddProcess(false);
                    handleCancelForm();
                  }}
                >
                  <FaArrowLeftLong />
                </div>

                <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                  {buttonState === "create" && "Add Process"}
                  {buttonState === "edit" && "View Process"}
                  {buttonState === "save" && "Edit Process"}
                </h4>

                {buttonState === "create" && (
                  <button
                    onClick={(e) => handleSubmitProcess(e, "insertProcess")}
                    className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                  >
                    Create
                  </button>
                )}
                {buttonState === "edit" && (
                  <div>
                    <button
                      onClick={() => {
                        setButtonState("save");
                      }}
                      className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 mr-5 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                    >
                      Edit
                    </button>
                    <button
                      onClick={handleCancelForm}
                      className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                    >
                      Cancel
                    </button>
                  </div>
                )}
                {buttonState === "save" && (
                  <div>
                    <button
                      onClick={(e) => handleSubmitProcess(e, "updateProcess")}
                      className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 mr-5 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                    >
                      Save
                    </button>
                    <button
                      onClick={handleCancelForm}
                      className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>

              {/* Form */}
              <div className="bg-white w-full overflow-x-scroll px-4 py-6 md:overflow-x-hidden rounded-b-2xl shadow-custom">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="col-span-full">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="col-span-3">
                        <label
                          htmlFor="processId"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Process ID
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="processId"
                            id="processId"
                            autoComplete="off"
                            disabled
                            value={formData.processId}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      <div className="col-span-3">
                        <label
                          htmlFor="taskId"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Task ID
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="taskId"
                            id="taskId"
                            autoComplete="off"
                            disabled={buttonState === "edit"}
                            value={formData.taskId}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      <div className="col-span-3">
                        <label
                          htmlFor="projectId"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Project ID
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="projectId"
                            id="projectId"
                            autoComplete="off"
                            disabled={buttonState === "edit"}
                            value={formData.projectId}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      <div className="col-span-3">
                        <label
                          htmlFor="processName"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Process Name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="processName"
                            id="processName"
                            autoComplete="off"
                            disabled={buttonState === "edit"}
                            value={formData.processName}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      {buttonState === "edit" && (
                        <>
                          <div className="col-span-3">
                            <label
                              htmlFor="onMachine"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              On Machine
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="onMachine"
                                id="onMachine"
                                autoComplete="off"
                                disabled={buttonState === "edit"}
                                value={formData.onMachine}
                                className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-span-3">
                        <label
                          htmlFor="assignTo"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Assign To
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="assignTo"
                            id="assignTo"
                            autoComplete="off"
                            disabled={buttonState === "edit"}
                            value={formData.assignTo}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      <div className="col-span-3">
                        <label
                          htmlFor="numberOfOPeratorRequired"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Number Of Operators Required
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="numberOfOPeratorRequired"
                            id="numberOfOPeratorRequired"
                            autoComplete="off"
                            disabled={buttonState === "edit"}
                            value={formData.numberOfOPeratorRequired}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      {buttonState === "edit" && (
                        <>
                          <div className="col-span-3">
                            <label
                              htmlFor="operatorWorking"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Operator Working
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="operatorWorking"
                                id="operatorWorking"
                                autoComplete="off"
                                disabled={buttonState === "edit"}
                                value={formData.operatorWorking}
                                className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-span-3">
                        <label
                          htmlFor="processStatus"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Process Status
                        </label>
                        <div className="mt-2">
                          <select
                            id="processStatus"
                            name="processStatus"
                            autoComplete="ProcessStatus"
                            disabled={buttonState === "edit"}
                            value={formData.processStatus}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => handleChange(e)}
                          >
                            <option value="">Select Status</option>
                            <option value="Assigned">Assigned</option>
                            <option value="InProgress">In Progress</option>
                            <option value="Completed">Completed</option>
                            <option value="Cancelled">Cancelled</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-span-3">
                        <label
                          htmlFor="numberOfPiecesToBeCreated"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Number Of Pieces To Be Created
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="numberOfPiecesToBeCreated"
                            id="numberOfPiecesToBeCreated"
                            autoComplete="off"
                            disabled={buttonState === "edit"}
                            value={formData.numberOfPiecesToBeCreated}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      <div className="col-span-3">
                        <label
                          htmlFor="numberOfPieceDone"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Number Of Pieces Done
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="numberOfPieceDone"
                            id="numberOfPieceDone"
                            autoComplete="off"
                            disabled={buttonState === "edit"}
                            value={formData.numberOfPieceDone}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      {(buttonState === "edit" || buttonState === "save") && (
                        <>
                          <div className="col-span-3">
                            <label
                              htmlFor="totalTimeTaken"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Total Time Taken
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="totalTimeTaken"
                                id="totalTimeTaken"
                                autoComplete="off"
                                disabled={buttonState === "edit"}
                                value={formData.totalTimeTaken}
                                className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-span-3">
                        <label
                          htmlFor="expectedCompletionTime"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Expected Completion Time
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="expectedCompletionTime"
                            id="expectedCompletionTime"
                            autoComplete="off"
                            disabled={buttonState === "edit"}
                            value={formData.expectedCompletionTime}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      {buttonState === "edit" && (
                        <>
                          <div className="col-span-6">
                            <label
                              htmlFor="remarks"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Remark By Operator
                            </label>
                            <div className="mt-2">
                              <textarea
                                name="remarks"
                                id="remarks"
                                autoComplete="off"
                                disabled={buttonState === "edit"}
                                value={formData.remarks}
                                className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                rows={3}
                                onChange={(e) => handleChange(e)}
                              ></textarea>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-span-6">
                        <label
                          htmlFor="processDescription"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Process Description
                        </label>
                        <div className="mt-2">
                          <textarea
                            name="processDescription"
                            id="processDescription"
                            autoComplete="off"
                            disabled={buttonState === "edit"}
                            value={formData.processDescription}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            rows={3}
                            onChange={(e) => handleChange(e)}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* table */}
        <div className="bg-white flex flex-col p-5 w-full h-fit rounded-2xl shadow-custom">
          <header className="flex justify-between">
            <div className="text-xl font-bold text-navy-700">
              {selectedOption === "allProject" && "All Projects"}
              {selectedOption === "allTask" && "All Tasks"}
              {selectedOption === "allProcess" && "All Processes"}
            </div>
            <select
              id="options"
              name="options"
              autoComplete="options"
              value={selectedOption}
              onChange={handleDropdownChange}
              className="block rounded-md border-0 p-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value="allProject">All Projects</option>
              <option value="allTask">All Tasks</option>
              <option value="allProcess">All Processes</option>
            </select>
          </header>

          {selectedOption === "allProject" && (
            <table className="min-w-full divide-y divide-gray-200 mt-8">
              <thead>
                <tr>
                  <th className="border-b border-gray-200 pb-[10px] text-start">
                    PROJECT ID
                  </th>
                  <th className="border-b border-gray-200 pb-[10px] text-start">
                    PROJECT NAME
                  </th>
                  <th className="border-b border-gray-200 pb-[10px] text-start">
                    STATUS
                  </th>
                  <th className="border-b border-gray-200 pb-[10px] text-start">
                    DELIVERY DATE
                  </th>
                  <th className="border-b border-gray-200 uppercase pb-[10px] text-start">
                    ACTION
                  </th>
                </tr>
              </thead>
              <tbody>
                {projectsData.length === 0 ? (
                  <tr>
                    <td
                      colSpan="5"
                      className="text-center text-sm font-bold text-navy-700 dark:text-white py-4"
                    >
                      No Data Found
                    </td>
                  </tr>
                ) : (
                  projectsData.map((row, index) => (
                    <tr key={index}>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.ProjectId}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.Name}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.Status}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.DeliveryDate}
                      </td>
                      <td>
                        <div className="flex gap-5 items-center">
                          <TiEye
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleViewProject(row)}
                          />
                          <FaUserEdit
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleViewProject(row);
                              setButtonState("save");
                            }}
                          />
                          <MdDelete
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDeleteProject(row.ProjectId)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          )}

          {selectedOption === "allTask" && (
            <table className="min-w-full divide-y divide-gray-200 mt-8">
              <thead>
                <tr>
                  <th className="border-b border-gray-200 pb-[10px] text-start">
                    TASK ID
                  </th>
                  <th className="border-b border-gray-200 pb-[10px] text-start">
                    TASK NAME
                  </th>
                  <th className="border-b border-gray-200 pb-[10px] text-start">
                    STATUS
                  </th>
                  <th className="border-b border-gray-200 pb-[10px] text-start">
                    NUMBER OF PROCESS
                  </th>
                  <th className="border-b border-gray-200 pb-[10px] text-start">
                    ACTION
                  </th>
                </tr>
              </thead>
              <tbody>
                {tasksData.length === 0 ? (
                  <tr>
                    <td
                      colSpan="5"
                      className="text-center text-sm font-bold text-navy-700 dark:text-white py-4"
                    >
                      No Data Found
                    </td>
                  </tr>
                ) : (
                  tasksData.map((row, index) => (
                    <tr key={index}>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.TaskId}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.TaskName}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.TaskStatus}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.NumberOfProcesses}
                      </td>
                      <td>
                        <div className="flex gap-5 items-center">
                          <TiEye
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleViewTask(row)}
                          />
                          <FaUserEdit
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleViewTask(row);
                              setButtonState("save");
                            }}
                          />
                          <MdDelete
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDeleteTask(row.TaskId)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          )}

          {selectedOption === "allProcess" && (
            <table className="min-w-full divide-y divide-gray-200 mt-8">
              <thead>
                <tr>
                  <th className="border-b border-gray-200 pb-[10px] text-start">
                    PROCESS ID
                  </th>
                  <th className="border-b border-gray-200 pb-[10px] text-start">
                    PROCESS NAME
                  </th>
                  <th className="border-b border-gray-200 pb-[10px] text-start">
                    STATUS
                  </th>
                  <th className="border-b border-gray-200 pb-[10px] text-start">
                    OPERATOR WORKING
                  </th>
                  <th className="border-b border-gray-200 uppercase pb-[10px] text-start">
                    ACTION
                  </th>
                </tr>
              </thead>
              <tbody>
                {processesData.length === 0 ? (
                  <tr>
                    <td
                      colSpan="5"
                      className="text-center text-sm font-bold text-navy-700 dark:text-white py-4"
                    >
                      No Data Found
                    </td>
                  </tr>
                ) : (
                  processesData.map((row, index) => (
                    <tr key={index}>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.ProcessId}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.ProcessName}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.ProcessStatus}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.OperatorWorking}
                      </td>
                      <td>
                        <div className="flex gap-5 items-center">
                          <TiEye
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleViewProcess(row)}
                          />
                          <FaUserEdit
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleViewProcess(row);
                              setButtonState("save");
                            }}
                          />
                          <MdDelete
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDeleteProcess(row.ProcessId)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Task;
