import React from "react";


const Notification = ({ className, data }) => {
  return (
    <>
      <div className={`${className} w-fit flex flex-col rounded-2xl shadow`}>
        {/* header */}
        <div className="flex h-fit items-center justify-between rounded-t-2xl bg-white px-4 pt-4 pb-[20px] shadow-custom border-b bg-white">
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Notification
          </h4>
        </div>

        {/* body */}
        <div>
          {/* notifications */}
          <div className="w-full h-fit p-4 border-b bg-primary-light">
            <h4 className="text-lg font-bold">
              Notification Title : This is notification sub title with increased
              width
            </h4>
            <p className="text-black text-secondary-light">
              this is a test notification body
            </p>
          </div>
          {/* notifications */}
          <div className="w-full h-fit p-4 border-b bg-primary-light">
            <h4 className="text-lg font-bold">
              Notification Title : This is notification sub title with increased
              width
            </h4>
            <p className="text-black text-secondary-light">
              this is a test notification body
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
