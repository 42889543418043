import React, { useState } from "react";

const ExcelUpload = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://cnc.swastik.digital/api/api.php?method=project&action=getAll"
  //     );
  //     const data = await response.json();
  //     console.log("Fetched data:", data);
  //     if (data.status) {
  //       const formattedData = data.msg.map((project) => ({
  //         projectName: project.Name,
  //         projectId: project.ProjectId,
  //         status: project.Status,
  //         startDate: project.StartDate,
  //         DeliveryDate: project.DeliveryDate,
  //         Description: project.Description,
  //         action: "",
  //       }));
  //       setTableData(formattedData);
  //     } else {
  //       alert(data.msg);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     alert("An error occurred while fetching the data");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("excelFile", file);

    try {
      const response = await fetch(
        "https://cnc.swastik.digital/api/api.php?method=uploadExcel",
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();
      if (result.status) {
        alert("File uploaded successfully");
      } else {
        alert("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("An error occurred while uploading the file");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex items-center justify-center gap-10 px-0">
      <input
        type="file"
        name="excelFile"
        onChange={handleFileChange}
        required
      />
      <button
        className="bg-secondary-dark hover:bg-primary-dark w-fit rounded-full text-white flex items-center justify-center px-8 py-2 cursor-pointer"
        type="submit"
      >
        Upload Excel
      </button>
    </form>
  );
};

export default ExcelUpload;
