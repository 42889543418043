import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import { TiEye } from "react-icons/ti";
import { FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const Machine = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [buttonState, setButtonState] = useState("create");

  const [formData, setFormData] = useState({
    machineId: "",
    machineName: "",
    category: "",
    lastServicingDate: "",
    operatorName: "",
    isActive: "",
    description: "",
    isMachineInUse: "",
  });

  const fetchData = async () => {
    try {
      // Fetch projects data
      const response = await fetch(
        "https://cnc.swastik.digital/api/api.php?method=machine&action=getAll"
      );
      const projectsResult = await response.json();
      if (projectsResult.status) {
        setTableData(projectsResult.msg);
        console.log(tableData);
      } else {
        console.log(projectsResult.msg);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("An error occurred while fetching the data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e, action) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `https://cnc.swastik.digital/api/api.php?method=machine&action=${action}&machineId=${encodeURIComponent(
          formData.machineId
        )}&machineName=${encodeURIComponent(
          formData.machineName
        )}&category=${encodeURIComponent(
          formData.category
        )}&lastServicingDate=${encodeURIComponent(
          formData.lastServicingDate
        )}&description=${encodeURIComponent(
          formData.description
        )}&isActive=${encodeURIComponent(formData.isActive)}`,
        {
          method: "GET", // Changed to GET because parameters are in URL
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.status) {
        alert(result.msg);
        fetchData();

        setFormData({
          machineName: "",
          category: "",
          lastServicingDate: "",
          description: "",
          isActive: false,
          machineId: null, // Clear the machineId
        });

        // Reset button state to default (create)
        setButtonState("create");
      } else {
        alert(result.msg);
      }
    } catch (error) {
      console.error("Error adding Machine:", error);
      alert("An error occurred while adding the machine");
    }
  };

  const handleDelete = async (machineId) => {
    try {
      const response = await fetch(
        `https://cnc.swastik.digital/api/api.php?method=machine&action=delete&machineId=${machineId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.status) {
        alert(result.msg);

        // Update tableData directly
        setTableData((prevTableData) =>
          prevTableData.filter((item) => item.MachineId !== machineId)
        );
      } else {
        alert(result.msg);
      }
    } catch (error) {
      console.error("Error deleting machine:", error);
      alert("An error occurred while deleting the machine");
    }
  };

  const handleViewMachine = (machine) => {
    setSelectedProject(machine);
    setButtonState("edit");
    setFormData({
      machineName: machine.Name,
      machineId: machine.MachineId,
      category: machine.Category,
      lastServicingDate: machine.LastServicingDoneOn,
      isMachineInUse: machine.IsMachineInUse,
      isActive: machine.IsActive,
      description: machine.Description,
    });
  };

  const handleCancelForm = () => {
    setFormData({
      machineName: "",
      category: "",
      lastServicingDate: "",
      description: "",
      isActive: false,
      machineId: null, // Clear the machineId
    });
    setButtonState("create");
  };

  return (
    <>
      <Navbar pageName="Machine" />
      <div className="flex flex-col lg:flex-row">
        {/* Machine Creation form */}
        <div className="w-full lg:mr-4 mb-4 lg:mb-0">
          {/* Header */}
          <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pt-4 pb-[20px] shadow-2xl shadow-gray-100 border-b">
            <h4 className="text-lg font-bold text-navy-700 dark:text-white">
              {buttonState === "create" && "Add Machine"}
              {buttonState === "edit" && "View Machine"}
              {buttonState === "save" && "Edit Machine"}
            </h4>
            {buttonState === "create" && (
              <button
                onClick={(e) => handleSubmit(e, "insert")}
                className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
              >
                Create
              </button>
            )}
            {buttonState === "edit" && (
              <div>
                <button
                  onClick={() => {
                    setButtonState("save");
                  }}
                  className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 mr-5 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                >
                  Edit
                </button>
                <button
                  onClick={handleCancelForm}
                  className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                >
                  Cancel
                </button>
              </div>
            )}
            {buttonState === "save" && (
              <div>
                <button
                  onClick={(e) => handleSubmit(e, "update")}
                  className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 mr-5 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                >
                  Save
                </button>
                <button
                  onClick={handleCancelForm}
                  className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>

          {/* Form */}
          <div className="bg-white w-full overflow-x-scroll px-4 py-6 md:overflow-x-hidden rounded-b-2xl shadow-custom">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="machineId"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Machine Id
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="machineId"
                    id="machineId"
                    value={formData.machineId}
                    onChange={handleChange}
                    disabled
                    autoComplete="off"
                    className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="machineName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Machine Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="machineName"
                    id="machineName"
                    value={formData.machineName}
                    onChange={handleChange}
                    disabled={buttonState === "edit"}
                    autoComplete="off"
                    className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Machine Category
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="category"
                    id="category"
                    value={formData.category}
                    onChange={handleChange}
                    disabled={buttonState === "edit"}
                    autoComplete="off"
                    className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="lastServicingDate"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Last Servicing Done On
                </label>
                <div className="mt-2">
                  <input
                    id="lastServicingDate"
                    name="lastServicingDate"
                    type="date"
                    value={formData.lastServicingDate}
                    onChange={handleChange}
                    disabled={buttonState === "edit"}
                    autoComplete="off"
                    className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="isActive"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Is Machine Active
                </label>
                <div className="mt-2">
                  <select
                    id="isActive"
                    name="isActive"
                    autoComplete="off"
                    value={formData.isActive}
                    disabled={buttonState === "edit"}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option>Active</option>
                    <option>Inactive</option>
                  </select>
                </div>
              </div>

              {buttonState === "edit" && (
                <>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="isMachineInUse"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Is Machine In Use
                    </label>
                    <div className="mt-2">
                      <select
                        id="isMachineInUse"
                        name="isMachineInUse"
                        autoComplete="off"
                        value={formData.isMachineInUse}
                        disabled
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option>In Use</option>
                        <option>Is Empty</option>
                      </select>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="operatorName"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Operator Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="operatorName"
                        id="operatorName"
                        value={formData.operatorName}
                        disabled
                        onChange={handleChange}
                        autoComplete="off"
                        className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="col-span-full">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Description
                </label>
                <div className="mt-2">
                  <textarea
                    id="description"
                    name="description"
                    rows={3}
                    value={formData.description}
                    disabled={buttonState === "edit"}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Table */}
        <div className="bg-white flex flex-col p-5 w-full h-fit rounded-2xl shadow-custom">
          <header className="flex justify-between">
            <div className="text-xl font-bold text-navy-700">All Machines</div>
          </header>

          <table className="min-w-full divide-y divide-gray-200 mt-8">
            <thead>
              <tr>
                <th className="border-b border-gray-200 pb-[10px] text-start">
                  MACHINE NAME
                </th>
                <th className="border-b border-gray-200 pb-[10px] text-start">
                  CATEGORY
                </th>
                <th className="border-b border-gray-200 pb-[10px] text-start">
                  LAST SERVICING DATE
                </th>
                <th className="border-b border-gray-200 pb-[10px] text-start">
                  IS MACHINE IN USE
                </th>
                <th className="border-b border-gray-200 uppercase pb-[10px] text-start">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.length === 0 ? (
                <tr>
                  <td
                    colSpan="5"
                    className="text-center text-sm font-bold text-navy-700 dark:text-white py-4"
                  >
                    No Data Found
                  </td>
                </tr>
              ) : (
                tableData.map((row, index) => (
                  <tr key={index}>
                    <td className="text-sm font-bold text-navy-700 text-black py-4">
                      {row.Name}
                    </td>
                    <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                      {row.Category}
                    </td>
                    <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                      {row.LastServicingDoneOn}
                    </td>
                    <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                      {row.IsMachineInUse}
                    </td>
                    <td>
                      <div className="flex gap-5 items-center">
                        <TiEye
                          className="text-secondary-light hover:text-primary-dark"
                          style={{
                            width: "30px",
                            height: "30px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleViewMachine(row)}
                        />
                        <FaUserEdit
                          className="text-secondary-light hover:text-primary-dark"
                          style={{
                            width: "30px",
                            height: "30px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleViewMachine(row);
                            setButtonState("save");
                          }}
                        />
                        <MdDelete
                          className="text-secondary-light hover:text-primary-dark"
                          style={{
                            width: "30px",
                            height: "30px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDelete(row.MachineId)}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Machine;
