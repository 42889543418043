import React, { useState } from "react";
import { FaUserAlt, FaTasks } from "react-icons/fa";
import Logo from "../Assets/Images/logo.png"
import { NavLink } from "react-router-dom";
import { IoSettings } from "react-icons/io5";
import { BiSolidDashboard } from "react-icons/bi";
import {
  HiOutlineBars3BottomLeft,
  HiOutlineBars3BottomRight,
} from "react-icons/hi2";
import "./Sidebar.css";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <BiSolidDashboard />,
    },
    {
      path: "/machine",
      name: "Machine",
      icon: <IoSettings />,
    },
    {
      path: "/task",
      name: "Task",
      icon: <FaTasks />,
    },
    {
      path: "/user",
      name: "User",
      icon: <FaUserAlt />,
    },
  ];

  return (
    <div className="SidebarContainer">
      <div
        style={{
          width: isOpen ? "300px" : "100px",
        }}
        className="sidebar"
      >
        <div
          className="top_section"
          style={{
            justifyContent: isOpen ? "space-between" : "center",
          }}
        >
          <h1
            style={{
              display: isOpen ? "block" : "none",
            }}
          >
            <img src={Logo} alt="Logo" />
          </h1>
          <div style={{ marginLeft: isOpen ? "50px" : "0px" }} className="bars">
            {isOpen ? (
              <>
                <HiOutlineBars3BottomRight onClick={toggle} />
              </>
            ) : (
              <>
                <HiOutlineBars3BottomLeft onClick={toggle} />
              </>
            )}
          </div>
        </div>
        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className="link"
            activeclassName="active"
            style={{
              justifyContent: isOpen ? "" : "center",
              padding: isOpen ? "20px 15px 20px 30px" : "20px 15px",
            }}
          >
            <div
              className="icon"
              style={{
                fontSize: isOpen ? "25px" : "30px",
              }}
            >
              {item.icon}
            </div>
            <div
              style={{ display: isOpen ? "block" : "none" }}
              className="link_text"
            >
              {item.name}
            </div>
          </NavLink>
        ))}
      </div>
      <main className="bg-primary-light">{children}</main>
    </div>
  );
};

export default Sidebar;
