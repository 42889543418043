import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import Logo from "../Assets/Images/log.svg";

const Login = () => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Clear all localStorage items when the component mounts
    localStorage.clear();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(""); // Reset any previous errors

    try {
      const response = await fetch(
        `https://cnc.swastik.digital/api/api.php?method=login&userId=${encodeURIComponent(
          userId
        )}&password=${encodeURIComponent(password)}`,
        {
          method: "GET", // GET method since parameters are in URL
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (data.success === 1) {
        const userData = data.UserData;
        const userName = userData.Name;
        const userRole = userData.Role;

        // Store user data in localStorage
        localStorage.setItem("userId", userId);
        localStorage.setItem("userName", userName);
        localStorage.setItem("userRole", userRole);

        // Redirect based on user role
        if (userRole === "operator") {
          navigate("/operator");
        } else if (userRole === "admin" || userRole === "superadmin") {
          navigate("/dashboard");
        }
      } else {
        setError(data.message || "Invalid userId or password");
      }
    } catch (err) {
      setError("Failed to connect to the server");
    }
  };

  return (
    <div className="login-container">
      <div className="forms-container">
        <div className="signin-signup">
          <form onSubmit={handleLogin} className="sign-in-form">
            <h2 className="title">Sign in</h2>
            {error && <p className="error">{error}</p>}
            <div className="input-field">
              <i className="fas fa-user" />
              <input
                type="text"
                placeholder="UserId"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                required
              />
            </div>
            <div className="input-field">
              <i className="fas fa-lock" />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <input type="submit" value="Login" className="btn solid" />
          </form>
        </div>
      </div>
      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h3>New here?</h3>
            <p>Kindly ask admin to create your account</p>
            <button className="btn transparent" id="sign-up-btn">
              Contact Admin
            </button>
          </div>
          <img src={Logo} className="image" alt="Login Illustration" />
        </div>
      </div>
    </div>
  );
};

export default Login;
