import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import { TiEye } from "react-icons/ti";
import { FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const User = () => {
  const [selectedOption, setSelectedOption] = useState("allOperator");
  const [buttonState, setButtonState] = useState("create");
  const [selectedProject, setSelectedProject] = useState(null);
  const [operatorData, setOperatorData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [superAdminData, setSuperAdminData] = useState([]);

  const handleDropdownChange = (e) => {
    const option = e.target.value;
    setSelectedOption(option);
  };

  const [formData, setFormData] = useState({
    userId: "",
    name: "",
    password: "",
    email: "",
    mobile: "",
    role: "",
    isActive: "",
    address: "",
  });

  const fetchData = async () => {
    try {
      // Fetch users data
      const operatorResponse = await fetch(
        `https://cnc.swastik.digital/api/api.php?method=user&action=getByRole&role=operator`
      );
      const operatorResult = await operatorResponse.json();
      if (operatorResult.status) {
        setOperatorData(operatorResult.msg);
      } else {
        console.log(operatorResult.msg);
      }

      // Fetch admin data
      const adminResponse = await fetch(
        `https://cnc.swastik.digital/api/api.php?method=user&action=getByRole&role=admin`
      );
      const adminResult = await adminResponse.json();
      if (adminResult.status) {
        setAdminData(adminResult.msg);
      } else {
        console.log(adminResult.msg);
      }

      // Fetch super admin data
      const superAdminresponse = await fetch(
        `https://cnc.swastik.digital/api/api.php?method=user&action=getByRole&role=superadmin`
      );
      const superAdminresult = await superAdminresponse.json();
      if (superAdminresult.status) {
        setSuperAdminData(superAdminresult.msg);
      } else {
        console.log(superAdminresult.msg);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("An error occurred while fetching the data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e, action) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `https://cnc.swastik.digital/api/api.php?method=user&action=${action}&userId=${encodeURIComponent(
          formData.userId
        )}&name=${encodeURIComponent(
          formData.name
        )}&password=${encodeURIComponent(
          formData.password
        )}&email=${encodeURIComponent(
          formData.email
        )}&mobile=${encodeURIComponent(
          formData.mobile
        )}&role=${encodeURIComponent(
          formData.role
        )}&isActive=${encodeURIComponent(
          formData.isActive
        )}&address=${encodeURIComponent(formData.address)}`,
        {
          method: "GET", // Changed to GET because parameters are in URL
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.status) {
        alert(result.msg);
        fetchData();
        handleCancelForm();
      } else {
        alert(result.msg);
      }
    } catch (error) {
      console.error("Error adding user:", error);
      alert("An error occurred while adding the user");
    }
  };

  const handleView = (user) => {
    setSelectedProject(user);
    setButtonState("edit");
    setFormData({
      userId: user.UserId,
      name: user.Name,
      mobile: user.Mobile,
      email: user.Email,
      role: user.Role,
      address: user.Address,
      isActive: user.IsAtive,
    });
  };

  const handleDelete = async (userId) => {
    try {
      const response = await fetch(
        `https://cnc.swastik.digital/api/api.php?method=user&action=delete&userId=${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.status) {
        alert("User deleted successfully");
        fetchData(); // Re-fetch the data to update the table
      } else {
        alert("Failed to delete the user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("An error occurred while deleting the user");
    }
  };

  const handleCancelForm = () => {
    setFormData({
      name: "",
      userId: "",
      role: "",
      email: "",
      mobile: "",
      address: "",
    });
    setButtonState("create");
  };

  return (
    <>
      <Navbar pageName="User" />
      <div className="flex flex-col lg:flex-row">
        {/* User Creation form */}
        <div className="w-full lg:mr-4 mb-4 lg:mb-0">
          {/* Header */}
          <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pt-4 pb-[20px] shadow-2xl shadow-gray-100 border-b">
            <h4 className="text-lg font-bold text-navy-700 dark:text-white">
              {buttonState === "create" && "Add User"}
              {buttonState === "edit" && "View User"}
              {buttonState === "save" && "Edit User"}
            </h4>
            {buttonState === "create" && (
              <button
                onClick={(e) => handleSubmit(e, "insert")}
                className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
              >
                Create
              </button>
            )}
            {buttonState === "edit" && (
              <div>
                <button
                  onClick={() => {
                    setButtonState("save");
                  }}
                  className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 mr-5 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                >
                  Edit
                </button>
                <button
                  onClick={handleCancelForm}
                  className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                >
                  Cancel
                </button>
              </div>
            )}
            {buttonState === "save" && (
              <div>
                <button
                  onClick={(e) => handleSubmit(e, "update")}
                  className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 mr-5 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                >
                  Save
                </button>
                <button
                  onClick={handleCancelForm}
                  className="linear rounded-[20px] bg-primary-light hover:bg-secondary-light px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>

          {/* Body */}
          <div className="bg-white w-full overflow-x-scroll px-4 py-6 md:overflow-x-hidden rounded-b-2xl shadow-custom">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="userId"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  User Id
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="userId"
                    id="userId"
                    autoComplete="off"
                    value={formData.userId}
                    disabled
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Full Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="off"
                    value={formData.name}
                    onChange={handleChange}
                    disabled={buttonState === "edit"}
                    className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="off"
                    value={formData.password}
                    onChange={handleChange}
                    disabled={buttonState !== "create"}
                    className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleChange}
                    disabled={buttonState === "edit"}
                    className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="mobile"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Mobile Number
                </label>
                <div className="mt-2">
                  <input
                    type="tel"
                    pattern="[0-9]{10}"
                    name="mobile"
                    id="mobile"
                    autoComplete="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    disabled={buttonState === "edit"}
                    className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Role
                </label>
                <div className="mt-2">
                  <select
                    id="role"
                    name="role"
                    autoComplete="role"
                    value={formData.role}
                    onChange={handleChange}
                    disabled={buttonState === "edit"}
                    className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="">Select Role</option>
                    <option value="operator">Operator</option>
                    <option value="admin">Admin</option>
                    <option value="superadmin">Super Admin</option>
                  </select>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="isActive"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Is Active
                </label>
                <div className="mt-2">
                  <select
                    id="isActive"
                    name="isActive"
                    autoComplete="isActive"
                    value={formData.isActive}
                    onChange={handleChange}
                    disabled={buttonState === "edit"}
                    className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Address
                </label>
                <div className="mt-2">
                  <textarea
                    id="address"
                    name="address"
                    rows={3}
                    value={formData.address}
                    onChange={handleChange}
                    disabled={buttonState === "edit"}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* table */}
        <div className="bg-white flex flex-col p-5 w-full h-fit rounded-2xl shadow-custom">
          <header className="flex justify-between">
            <div className="text-xl font-bold text-navy-700">
              {selectedOption === "allOperator" && "All Admin"}
              {selectedOption === "allAdmin" && "All Tasks"}
              {selectedOption === "allSuperAdmin" && "All Super Admin"}
            </div>
            <select
              id="options"
              name="options"
              autoComplete="options"
              value={selectedOption}
              onChange={handleDropdownChange}
              className="block rounded-md border-0 p-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value="allOperator">All Operator</option>
              <option value="allAdmin">All Admin</option>
              <option value="allSuperAdmin">All Super Admin</option>
            </select>
          </header>

          <table className="min-w-full divide-y divide-gray-200 mt-8">
            <thead>
              <tr>
                <th className="border-b border-gray-200 pb-[10px] text-start">
                  USER ID
                </th>
                <th className="border-b border-gray-200 pb-[10px] text-start">
                  USER NAME
                </th>
                <th className="border-b border-gray-200 pb-[10px] text-start">
                  ROLE
                </th>
                <th className="border-b border-gray-200 pb-[10px] text-start">
                  MOBILE
                </th>
                <th className="border-b border-gray-200 uppercase pb-[10px] text-start">
                  ACTION
                </th>
              </tr>
            </thead>
            {selectedOption === "allOperator" && (
              <tbody>
                {operatorData.length === 0 ? (
                  <tr>
                    <td
                      colSpan="5"
                      className="text-center text-sm font-bold text-navy-700 dark:text-white py-4"
                    >
                      No Data Found
                    </td>
                  </tr>
                ) : (
                  operatorData.map((row, index) => (
                    <tr key={index}>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.UserId}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.Name}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.Role}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.Mobile}
                      </td>
                      <td>
                        <div className="flex gap-5 items-center">
                          <TiEye
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleView(row)}
                          />
                          <FaUserEdit
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleView(row);
                              setButtonState("save");
                            }}
                          />
                          <MdDelete
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDelete(row.UserId)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            )}

            {selectedOption === "allAdmin" && (
              <tbody>
                {adminData.length === 0 ? (
                  <tr>
                    <td
                      colSpan="5"
                      className="text-center text-sm font-bold text-navy-700 dark:text-white py-4"
                    >
                      No Data Found
                    </td>
                  </tr>
                ) : (
                  adminData.map((row, index) => (
                    <tr key={index}>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.UserId}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.Name}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.Role}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.Mobile}
                      </td>
                      <td>
                        <div className="flex gap-5 items-center">
                          <TiEye
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleView(row)}
                          />
                          <FaUserEdit
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleView(row);
                              setButtonState("save");
                            }}
                          />
                          <MdDelete
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDelete(row.UserId)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            )}

            {selectedOption === "allSuperAdmin" && (
              <tbody>
                {superAdminData.length === 0 ? (
                  <tr>
                    <td
                      colSpan="5"
                      className="text-center text-sm font-bold text-navy-700 dark:text-white py-4"
                    >
                      No Data Found
                    </td>
                  </tr>
                ) : (
                  superAdminData.map((row, index) => (
                    <tr key={index}>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.UserId}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.Name}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.Role}
                      </td>
                      <td className="text-sm font-bold text-navy-700 dark:text-white py-4">
                        {row.Mobile}
                      </td>
                      <td>
                        <div className="flex gap-5 items-center">
                          <TiEye
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleView(row)}
                          />
                          <FaUserEdit
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleView(row);
                              setButtonState("save");
                            }}
                          />
                          <MdDelete
                            className="text-secondary-light hover:text-primary-dark"
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDelete(row.UserId)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default User;
