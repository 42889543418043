import React, { useState, useEffect } from "react";
import Notification from "../Components/Notification";
import Navbar from "../Components/Navbar";
import MachineCard from "../Components/MachineCard";

const Operator = () => {
  const [tasks, setTasks] = useState([]);
  const userName = localStorage.getItem("userName");

  useEffect(() => {
    // Fetch tasks from the API
    fetch("https://cnc.swastik.digital/api/api.php?method=task&action=getAll")
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the data to inspect the structure
        if (data.success) {
          setTasks(data.msg); // Use the 'msg' array from the API response
        } else {
          console.error("Failed to fetch tasks:", data.msg);
        }
      })
      .catch((error) => console.error("Error fetching tasks:", error));
  }, []);

  return (
    <>
      <div className="bg-primary-light h-full">
        <Navbar pageName="Operator" />
        <div className="flex items-start justify-between p-5 gap-5">
          {/* Tasks */}
          <div>
            {/* Pending Task */}
            <div className="flex flex-col gap-5">
              {/* heading */}
              <div className="flex items-center justify-between">
                <h4 className="font-bold text-lg">
                  In Progress/Allocated Task
                </h4>
              </div>
              {/* cards */}
              <div className="flex gap-5 overflow-x-auto w-full custom-scrollbar pb-5">
                {tasks.map((task) => {
                  if (task.OperatorsWorking.includes(userName)) {
                    return (
                      <div key={task.Id} className="shrink-0">
                        <MachineCard task={task} />
                      </div>
                    );
                  }
                  return null; // Don't render anything if userId is not in OperatorsWorking
                })}
              </div>
            </div>
            {/* All Task */}
            <div className="flex flex-col gap-5 pt-4">
              <div className="flex items-center justify-between">
                <h4 className="font-bold text-lg">All Pending Task</h4>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                {tasks.map((task) => (
                  <MachineCard key={task.Id} task={task} />
                ))}
              </div>
            </div>
          </div>
          {/* Information Card */}
          <div className="hidden xl:block">
            <Notification />
          </div>
        </div>
      </div>
    </>
  );
};

export default Operator;
