import React, { useState } from "react";
import { MdBarChart } from "react-icons/md";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import PieChart from "../Components/PieChart";
import BarChart from "../Components/BarChart";
import {
  pieChartData,
  pieChartOptions,
  barChartDataWeeklyRevenue,
  barChartOptionsWeeklyRevenue,
} from "../Data/charts";

const ChartCard = ({ className }) => {
  const [showBar, setShowBar] = useState(true);

  const changeGraph = () => {
    setShowBar(!showBar);
  };

  return (
    <>
      <div
        className={`bg-white flex flex-col w-full rounded-2xl shadow-custom ${className}`}
      >
        <div className="flex justify-between px-8 mt-4">
          <h2 className="text-lg font-bold text-navy-700 dark:text-white">
            Weekly Report
          </h2>
          <button className="!linear z-[1] flex items-center justify-center rounded-lg p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100  bg-primary-light">
            {!showBar ? (
              <MdBarChart
                className="h-6 w-6 hover:text-primary-dark"
                onClick={changeGraph}
              />
            ) : (
              <BiSolidPieChartAlt2
                className="h-6 w-6 hover:text-primary-dark"
                onClick={changeGraph}
              />
            )}
          </button>
        </div>

        <div className="flex self-end">
          {!showBar ? (
            <>
              <PieChart options={pieChartOptions} series={pieChartData} />
            </>
          ) : (
            <>
              <BarChart
                chartData={barChartDataWeeklyRevenue}
                chartOptions={barChartOptionsWeeklyRevenue}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ChartCard;
