import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import LogoutNotification from "../Components/LogoutNotification";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoMdArrowDropdownCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import BgImage from "../Assets/Images/machine_bg.png";
import { useParams } from "react-router-dom";

const MachineDetail = () => {
  const { taskId } = useParams();
  const [taskData, setTaskData] = useState(null);
  const [processData, setProcessData] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [logout, setLogout] = useState(false);
  const [edit, setEdit] = useState(false);
  const [logoutTimeout, setLogoutTimeout] = useState(null);
  const userName = localStorage.getItem("userName");

  useEffect(() => {
    // Fetch task details from the API
    fetch(
      `https://cnc.swastik.digital/api/api.php?method=task&action=get&taskId=${taskId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setTaskData(data.msg);
        } else {
          console.error("Failed to fetch tasks:", data.msg);
        }
      })
      .catch((error) => console.error("Error fetching tasks:", error));

    // Fetch processes related to this taskId from the API
    fetch(
      `https://cnc.swastik.digital/api/api.php?method=process&action=getByTaskId&taskId=${taskId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setProcessData(data.msg);
        } else {
          console.error("Failed to fetch processes:", data.msg);
        }
      })
      .catch((error) => console.error("Error fetching processes:", error));
  }, [taskId]);

  const handleLogout = () => {
    setLogout(!logout);
    // localStorage.removeItem("userId"); // Remove userId from localStorage
    // window.location.href = "/login"; // Redirect to login page
  };

  const handlePunch = () => {
    // Toggle Punch In/Out
    // if (isOperatorWorking) {
    //   // Handle Punch Out logic
    //   setOperatorWorking([]);
    // } else {
    //   // Handle Punch In logic
    //   setOperatorWorking([
    //     /* Your userId here */
    //   ]);
    // }
    // Optionally trigger form submission
    // handleSubmit();
  };

  const handleSubmit = async (processId) => {
    // Get values from form fields
    const formData = {
      processId: document.getElementById(`processId-${processId}`)?.value || "",
      operatorWorking:
        document.getElementById(`working-operator-${processId}`)?.value || "",
      status: document.getElementById(`status-${processId}`)?.value || "",
      numPiecesDone:
        document.getElementById(`numPiecesDone-${processId}`)?.value || "",
      timeTaken:
        document.getElementById(`total-time-${processId}`)?.value || "",
      onMachine:
        document.getElementById(`machine-used-${processId}`)?.value || "",
      remarks: document.getElementById(`remarks-${processId}`)?.value || "",
    };

    // Basic validation
    for (const key in formData) {
      if (!formData[key] && key !== "remarks") {
        alert(
          `Please fill out the ${key.replace(/([A-Z])/g, " $1").toLowerCase()}.`
        );
        return;
      }
    }

    try {
      const response = await fetch(
        `https://cnc.swastik.digital/api/api.php?method=process&action=updateByOperator&operatorWorking=${encodeURIComponent(
          formData.operatorWorking
        )}&processStatus=${encodeURIComponent(
          formData.status
        )}&totalTimeTaken=${encodeURIComponent(
          formData.timeTaken
        )}&numPiecesDone=${encodeURIComponent(
          formData.numPiecesDone
        )}&onMachine=${encodeURIComponent(
          formData.onMachine
        )}&processId=${encodeURIComponent(
          formData.processId
        )}&remarks=${encodeURIComponent(formData.remarks)}`,
        {
          method: "GET", // Changed to GET because parameters are in URL
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.status) {
        setEdit(false);
        setLogout(!logout);
        const timeout = setTimeout(() => {
          localStorage.removeItem("userId");
          window.location.href = "/";
        }, 30000);

        // Store the timeout ID to clear it if needed
        setLogoutTimeout(timeout);

        // Optionally call a function to refresh data or update UI
      } else {
        alert(result.msg);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form");
    }
  };

  if (!taskData) {
    return <div>Loading...</div>; // Show a loading indicator while data is being fetched
  }

  const handleCancelLogout = () => {
    if (logoutTimeout) {
      clearTimeout(logoutTimeout); // Clear the timeout
      setLogoutTimeout(null); // Reset the timeout state
      setLogout(!logout);
    }
  };

  const handleImmediateLogout = () => {
    if (logoutTimeout) {
      clearTimeout(logoutTimeout); // Clear the timeout
    }
    localStorage.removeItem("userId"); // Remove userId from localStorage
    window.location.href = "/login"; // Redirect to login page
  };

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // const [isFlipped, setIsFlipped] = useState(false);

  // const handleDetailsClick = () => {
  //   setIsFlipped(!isFlipped);
  // };

  return (
    <>
      {logout && (
        <LogoutNotification
          onEditTask={handleCancelLogout}
          onLogoutNow={handleImmediateLogout}
        />
      )}

      <div className="bg-primary-light h-[100vh]">
        <Navbar pageName="Task Detail" />
        <div className="flex flex-col items-start justify-between p-5">
          {/* Header */}
          <div className="bg-white flex w-full items-center p-4 gap-5 rounded-t-2xl border-b">
            <Link
              to="/"
              className="hover:bg-primary-light bg-white border cursor-pointer border py-2 px-4 rounded-full text-primary-dark"
            >
              <FaArrowLeftLong />
            </Link>
            <h4 className="font-bold text-lg">Task Details</h4>
          </div>
          {/* Body */}
          <div className="bg-primary-light w-full border p-5 flex gap-5 flex-col lg:flex-row">
            {/* Profile Card */}
            <div className="bg-white w-full lg:w-fit h-fit rounded-xl flex items-start justify-between shadow-custom flex-col p-3 gap-5">
              <img
                src={BgImage}
                alt=""
                className="rounded-xl w-full lg:w-fit"
              />
              <h4 className="font-bold text-black text-xl">
                {taskData.TaskName}
              </h4>
              <div>
                <span className="font-medium text-black text-md">
                  Created On :{" "}
                </span>
                <span className=" text-secondary-light text-md">
                  {taskData.CreatedOn}
                </span>
              </div>

              <div>
                <span className="font-medium text-black text-md">
                  Delivery Date :{" "}
                </span>
                <span className=" text-secondary-light text-md">
                  {taskData.DeliveryDate}
                </span>
              </div>

              <div className="bg-secondary-dark hover:bg-primary-dark w-full rounded-full text-white flex items-center justify-center p-2 cursor-pointer">
                {taskData.TaskStatus}
              </div>
              <div className="bg-secondary-dark hover:bg-primary-dark w-full rounded-full text-white flex items-center justify-center p-2 cursor-pointer">
                Details
              </div>
            </div>
            {/* Task Tabs */}
            <div className="flex flex-col w-full gap-5">
              {/* Task Tab */}
              <div className="w-full">
                {processData.length > 0 ? (
                  processData.map((process, index) => (
                    <div key={process.Id} className="w-full mb-4">
                      <div
                        className={`bg-white w-full h-fit flex items-center justify-between p-5 cursor-pointer ${
                          openIndex === index
                            ? "rounded-t-2xl border-b"
                            : "rounded-2xl"
                        }`}
                        onClick={() => handleToggle(index)}
                      >
                        <div>
                          <h4 className="font-bold text-black text-xl">
                            {process.ProcessName} :{" "}
                            <input
                              type="text"
                              id={`processId-${process.Id}`}
                              className="font-bold text-black text-xl bg-white"
                              defaultValue={process.ProcessId || ""}
                              disabled
                            />
                          </h4>
                          <h4 className="font-medium text-secondary-light text-lg">
                            {process.ProcessStatus}
                          </h4>
                        </div>
                        <IoMdArrowDropdownCircle
                          className={`w-10 h-10 transform transition-transform duration-300 ${
                            openIndex === index ? "rotate-180" : ""
                          }`}
                        />
                      </div>
                      {openIndex === index && (
                        <div className="bg-white w-full overflow-x-scroll px-4 py-6 md:overflow-x-hidden rounded-b-2xl shadow-custom">
                          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                              <label
                                htmlFor={`working-operator-${process.Id}`}
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Working Operator Name
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  id={`working-operator-${process.Id}`}
                                  className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  defaultValue={
                                    process.OperatorWorking || userName
                                  }
                                  disabled={!edit}
                                  required
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor={`status-${process.Id}`}
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Status
                              </label>
                              <div className="mt-2">
                                <select
                                  id={`status-${process.Id}`}
                                  className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  defaultValue={
                                    process.ProcessStatus || "Completed"
                                  }
                                  disabled={!edit}
                                  required
                                >
                                  <option>Completed</option>
                                  <option>In Progress</option>
                                  <option>Pending</option>
                                  <option>Cancelled</option>
                                </select>
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor={`pieces-done-${process.Id}`}
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Number of Pieces To Be Created
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  id={`pieces-done-${process.Id}`}
                                  className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  defaultValue={
                                    process.NumberOfPieceToBeCreated || ""
                                  }
                                  disabled
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor={`numberOfPiecesDone-${process.Id}`}
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Number Of Pieces Done
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  id={`numPiecesDone-${process.Id}`}
                                  className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  defaultValue={
                                    process.NumberOfPiecesDone || ""
                                  }
                                  disabled={!edit}
                                  required
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor={`total-time-${process.Id}`}
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Total Time Taken
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  id={`total-time-${process.Id}`}
                                  className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  defaultValue={process.TotalTimeTaken || ""}
                                  disabled={!edit}
                                  required
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor={`machine-used-${process.Id}`}
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Machine Used
                              </label>
                              <div className="mt-2">
                                <select
                                  id={`machine-used-${process.Id}`}
                                  className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  defaultValue={
                                    process.MachineOn ||
                                    "Category : Machine Name"
                                  }
                                  disabled={!edit}
                                  required
                                >
                                  <option>Category : Machine Name</option>
                                  <option>XYZ</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-span-full">
                              <label
                                htmlFor={`remarks-${process.Id}`}
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Remarks If Any
                              </label>
                              <div className="mt-2">
                                <textarea
                                  id={`remarks-${process.Id}`}
                                  rows={3}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  defaultValue={process.Reamrks || ""}
                                  disabled={!edit}
                                />
                              </div>
                            </div>

                            <div className="col-span-full">
                              <label
                                htmlFor={`fetch-operators-${process.Id}`}
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Fetch Previous Operators
                              </label>
                              <div className="flex w-full h-fit px-2 py-5 border-2 rounded-md mt-2 justify-end">
                                <div className="bg-secondary-dark hover:bg-primary-dark w-full md:w-fit rounded-full text-white flex items-center justify-center px-8 py-2 cursor-pointer">
                                  Fetch
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center justify-start mt-5 gap-5 flex-col md:flex-row">
                            {process.OperatorWorking[0] === "userId" ? (
                              <>
                                <div className="bg-secondary-dark hover:bg-primary-dark w-full md:w-fit rounded-full text-white flex items-center justify-center px-5 py-2 cursor-pointer">
                                  Punch Out
                                </div>
                                {edit ? (
                                  <>
                                    <div
                                      className="bg-secondary-dark hover:bg-primary-dark w-full md:w-fit rounded-full text-white flex items-center justify-center px-5 py-2 cursor-pointer"
                                      onClick={() => handleSubmit(process.Id)}
                                    >
                                      Save Task
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    className="bg-secondary-dark hover:bg-primary-dark w-full md:w-fit rounded-full text-white flex items-center justify-center px-5 py-2 cursor-pointer"
                                    onClick={() => setEdit(!edit)}
                                  >
                                    Edit Task
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <div
                                  className="bg-secondary-dark hover:bg-primary-dark w-full md:w-fit rounded-full text-white flex items-center justify-center px-5 py-2 cursor-pointer"
                                  onClick={() => handleSubmit(process.Id)}
                                >
                                  Punch In
                                </div>
                                {!edit ? (
                                  <>
                                    <div
                                      className="bg-secondary-dark hover:bg-primary-dark w-full md:w-fit rounded-full text-white flex items-center justify-center px-5 py-2 cursor-pointer"
                                      onClick={() => setEdit(!edit)}
                                    >
                                      Edit Task
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    className="bg-secondary-dark hover:bg-primary-dark w-full md:w-fit rounded-full text-white flex items-center justify-center px-5 py-2 cursor-pointer"
                                    onClick={() => setEdit(!edit)}
                                  >
                                    Cancel
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No processes found for this task.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MachineDetail;
