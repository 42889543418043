import React, { useEffect, useState } from "react";
import InfoCard from "../Components/InfoCard";
import { IoSettings } from "react-icons/io5";
import { IoIosPeople } from "react-icons/io";
import ChartCard from "../Components/ChartCard";
import Navbar from "../Components/Navbar";

const Dashboard = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      // Fetch data
      const response = await fetch(
        `https://cnc.swastik.digital/api/api.php?method=dashboard`
      );
      const result = await response.json();
      if (result.status) {
        console.log(result);
        setData(result);
      } else {
        console.log(result.msg);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("An error occurred while fetching the data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Navbar pageName="Dashboard" />
      {/* info Cards */}
      <div className="flex flex-col lg:flex-row items-center justify-between">
        <InfoCard
          icon={<IoIosPeople className="text-primary-dark w-[30px] h-[30px]" />}
          cardTitle="Total Users"
          cardDesc={data.UserCount}
          className="lg:mr-4 mb-4 lg:mb-0"
        />
        <InfoCard
          icon={<IoSettings className="text-primary-dark w-[30px] h-[30px]" />}
          cardTitle="Total Machines"
          cardDesc={data.MachineCount}
          className="lg:mr-4 mb-4 lg:mb-0"
        />
        <InfoCard
          icon={<IoSettings className="text-primary-dark w-[30px] h-[30px]" />}
          cardTitle="Total Projects"
          cardDesc={data.ProjectCount}
          className="lg:mr-4 mb-4 lg:mb-0"
        />
        <InfoCard
          icon={<IoSettings className="text-primary-dark w-[30px] h-[30px]" />}
          cardTitle="Total Task"
          cardDesc={data.TaskCount}
        />
      </div>

      {/* graphs */}
      <div className="flex flex-col lg:flex-row mt-4">
        <ChartCard className="lg:mr-4 mb-4 lg:mb-0" />
        <ChartCard />
      </div>
    </>
  );
};

export default Dashboard;
