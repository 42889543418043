import React, { useState } from "react";
import { IoIosNotifications } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const Navbar = ({ pageName }) => {
  const navigate = useNavigate();
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const userName = localStorage.getItem("userName");

  const handleLogout = () => {
    // Clear the userId from localStorage
    localStorage.clear();

    // Redirect to the login page
    navigate("/");
  };

  return (
    <>
      <div className="sticky top-4 z-40 flex  items-center justify-end lg:justify-between bg-white/10 p-2 mx-5 backdrop-blur-xl">
        {/* Left Part - BreadCrumb */}
        <div className="hidden lg:block">
          <div className="h-6 w-[224px] pt-1">
            <a className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white">
              Pages
              <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
                {" "}
                /{" "}
              </span>
            </a>
            <a className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white">
              {pageName}
            </a>
          </div>
          <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
            <a className="font-bold capitalize hover:text-navy-700 dark:hover:text-white">
              {pageName}
            </a>
          </p>
        </div>
        {/* Right Part */}
        <div className="mt-[3px] flex h-[61px] w-fit items-center justify-around gap-5 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 ">
          {/* search */}
          <div className="flex h-full items-center rounded-full bg-primary-light text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
            <p className="pl-3 pr-2 text-xl">
              <CiSearch className="w-5 h-5 text-secondary-light cursor-pointer" />
            </p>
            <input
              type="text"
              placeholder="Search..."
              className="block h-full w-full rounded-full bg-primary-light text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
            />
          </div>

          {/* Notification */}
          <div className="relative flex block xl:hidden">
            <IoIosNotifications
              className="w-7 h-7 text-secondary-light cursor-pointer "
              onClick={() => setIsOpenNotification(!isOpenNotification)}
            />
            {/* Notifications */}
            {isOpenNotification && (
              <div className="py-2 top-10 right-0 w-max absolute z-10 origin-top-right transition-all duration-200 ease-in-out sm:block hidden">
                <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
                  <div className="flex items-center justify-between">
                    <p className="text-base font-bold text-navy-700 dark:text-white">
                      Notifications
                    </p>
                    <a className="text-sm font-bold text-navy-700 dark:text-white">
                      See All
                    </a>
                  </div>
                  <button className="flex w-full items-center">
                    <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-primary-light py-4 text-2xl text-white">
                      <IoIosNotifications className="w-7 h-7 text-secondary-light sm:block hidden" />
                    </div>
                    <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                      <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                        New Update: User Is Created
                      </p>
                      <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                        A new update for your downloaded item is available!
                      </p>
                    </div>
                  </button>
                  <button className="flex w-full items-center">
                    <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-primary-light py-4 text-2xl text-white">
                      <IoIosNotifications className="w-7 h-7 text-secondary-light sm:block hidden" />
                    </div>
                    <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                      <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                        New Update: VMS Dashboard
                      </p>
                      <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                        A new update for your downloaded item is available!
                      </p>
                    </div>
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* profile */}
          <div className="relative flex">
            <div className="flex">
              <FaUserCircle
                className="w-10 h-10 cursor-pointer text-secondary-light"
                onClick={() => setIsOpenProfile(!isOpenProfile)}
              />
            </div>
            <div
              className={`absolute z-10 top-12 -left-[180px] w-max rounded-[20px] bg-white shadow-xl shadow-shadow-500 dark:bg-navy-700 dark:text-white dark:shadow-none transition-transform duration-300 ease-in-out ${
                isOpenProfile ? "scale-100 opacity-100" : "scale-90 opacity-0"
              }`}
              style={{
                transformOrigin: "top right",
                transform: isOpenProfile ? "scale(1)" : "scale(0.9)",
                transition: "transform 0.3s ease, opacity 0.3s ease",
              }}
            >
              <div className="flex flex-col justify-start p-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, {userName}
                  </p>
                </div>
                <div className="h-px w-full bg-gray-200 dark:bg-white/20 mt-2" />
                <div className="flex flex-col p-4">
                  <span className="text-sm text-gray-800 dark:text-white hover:text-gray-600 dark:hover:text-gray-300">
                    Profile Settings
                  </span>
                  <span
                    onClick={handleLogout}
                    className="mt-3 text-sm font-medium text-red-500 transition duration-150 ease-out hover:text-red-600 cursor-pointer"
                  >
                    Log Out
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
